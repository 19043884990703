<template>
  <div class="container">
    <div class="title">Submit account deletion</div>
    <div class="center">
      <input class="email" v-model="email" placeholder="Please enter your email address." />
      <p v-if="mailError" class="tip">{{mailText}}</p>
      <div class="btn" @click="handleSubmit">Delete</div>
    </div>
    <div v-if="isSuccessful" class="delSuccess">Account deleted successfully</div>
  </div>
</template>
<script>
import axios from "axios"
export default {
  name: "Privacy",
  data() {
    return {
      email: "",
      mailError: false,
      mailText: "",
      isSuccessful: false,
    }
  },
  methods: {
    handleSubmit(){
      var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
      console.log("reg.test(this.email_info)",reg.test(this.email));
      if(this.email == "" || this.email == null){
        this.mailError = true;
        this.mailText = "Please enter the correct email address"
      }else if(!reg.test(this.email)){
        this.mailError = true;
        this.mailText = "Please enter the correct email address"
      }else{
        this.mailError = false;
        axios({
          method:'post',
          url:"https://www.nrealm.com/api/v1/delete_account",
          data:{data: {email:this.email,}},
          headers:{
            "Content-Type":'application/json'
          }
        }).then((res)=>{
          // console.log(res)
          // console.log(res.data.code)
          // console.log(res.data.code == 200)
          if(res.data.code == 0){
            console.log("账号删除成功")
            this.isSuccessful = true;
            setTimeout(()=>{
              this.isSuccessful = false;
            },3000)
          }else{
            this.mailError = true;
            this.mailText = res.data.msg;
          }

        }).catch(err=>{
          this.mailError = true;
          this.mailText = "System exception";
        })
      }
    }
  },
  mounted() {

  }
}
</script>
<style scoped lang="scss">
.container {
  height: 100vh;
  background: #E3E7EC;
  overflow: hidden;
  color: #222;
  //font-size: 0.12px;
  .title{
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    width: 100%;
    box-shadow: 0 1px 2px rgba(102, 102, 102,0.21);
    text-align: left;
  }
  .center {
    width: 300px;
    position: absolute;
    left: 15px;
    top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 110px;

    .email {
      width: 200px;
      height: 42px;
      border: 1px solid #666666;
      border-radius: 4px;
      padding: 0 5px;
    }

    input:focus {
      outline: none;

    }

    .tip{
      line-height: 15px;
      margin-bottom: 0;
      margin-top: 5px;
      font-size: 12px;
      color: red;
    }

    .btn {
      width: 80px;
      height: 40px;
      display: inline-block;
      line-height: 40px;
      text-align: center;
      background: #ffd453;
      color: #333;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .delSuccess{
    padding: 15px 20px;
    display: inline-block;
    border-radius: 6px;
    background: rgba(0,0,0,0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}</style>
